@font-face{
  font-display:swap;
  font-family:"VCROSDMono";
  font-style:normal;
  font-weight:normal;
  src:url(https://adl-foundation.adidas.com/prod/brands/yeezy/asset-fonts/files/VCR_OSD_MONO.woff2) format("woff2"),url(https://adl-foundation.adidas.com/prod/brands/yeezy/asset-fonts/files/VCR_OSD_MONO.woff) format("woff")
}

body {
  font-family: "VCROSDMono",Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

#hp-banner .hp-b-cta {
  left: 0;
  bottom: -35px;
  box-shadow: #757575 5px 4px;
}

.centered {
  justify-content: center;
  align-items: center;
  display: flex;
}

#app-header {
  background: none;
  background-size: 100% 1px;
  background-color: transparent;
  position: relative;
  z-index: 999;
}

.MuiCard-root.product-card {
  background-color: transparent;
  width: 100%;
  max-width: 700px;
  padding: 18px;
  border-radius: 12px;
  position: relative;
}

.asset-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #202231;
  border-radius: 18px;
  padding: 24px;
}

.asset-row .details {
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 54px);
}

.asset-row .details h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.router-container {
  position: relative;
  min-height: calc(100vh - 215px);
}

.asset-row span.image-wrapper {
  width: 54px;
  height: 54px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.asset-row span.image-wrapper img {
  border-radius: 5px;
}

.asset-row .input {
  background-color: #161522;
  color: white;
  width: 100%;
  text-align: right;
  border-radius: 10px;
}

.asset-row .input input {
  text-align: right;
  color: white;
}

.asset-row .MuiSelect-root .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: rgb(255 255 255);
}

.arrow-wrap {
  height: 59px;
  width: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #202231;
  border: 3px solid #161522;
  position: absolute;
  z-index: 3;
  top: -21%
}
.deets .MuiFormControl-root label {
  color: white;
}

.deets .MuiFormControl-root input {
  color: white;
}

.deets .MuiFormControl-root fieldset {
}
.asset-row .MuiInputBase-formControl {
  border-radius: 10px;
}

.asset-row .MuiGrid-item {
  flex-direction: row;
  display: flex;
}

#app-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 69px);
}

@media (max-width: 767px) {
  .asset-row .top-row {
    margin-bottom: 12px;
  }

  .arrow-wrap {
  }
  .MuiCard-root.product-card {
    height: auto;
    padding: 0;
    padding-bottom: 69px;
  }
  #app-container {
    height: auto;
    margin-top: 28px;
  }
  .buy-button-wrapper {
    height: 100px;
  }
  #shop-grid {
    padding-bottom: 100px;
  }
}

#bg-video {
  position: fixed;
  height: 100%;
  z-index: 0;
  filter: blur(15px);
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0.3;
}

.product-card .MuiOutlinedInput-root {
  background-color: transparent;
}

.MuiInputLabel-root {
  color: #09d3ac !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #09d3ac !important;
}

.MuiOutlinedInput-root input {
  color: #09d3ac !important;
}
#hiw-section .hp-hiw-image {
  height: 300px;
  width: 300px;
  margin: 0 auto;
  border-radius: 12px;
  border: 2px solid #614961;
  background-position: center;
  background-size: cover;
  margin-bottom: 18px;
}

#hiw-section .step-number {
  height: 50px;
  width: 50px;
  background-color: #62bfb1;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -33px;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  border: #fff 3px solid;
}

.textShadow {
  text-shadow: 2px 4px #ffffff;
}
.textShadowGrey {
  text-shadow: 2px 2px #757575;
}

#hp-banner {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-image:
          url(https://cdn.dribbble.com/users/1107982/screenshots/16713837/media/1a1474c6a79a70840d214382fc0e6117.jpg?compress=1&resize=800x600);
}

#drawer {
  color: #ffffff;
}

#header-buttons {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
}

#WEB3_CONNECT_MODAL_ID .web3modal-modal-lightbox {
  z-index: 999999 !important;
}

.image-uploader-container {
  width: 100%;
  height: 0;
  padding-top: 133.33333%;
  border-radius: 12px;
  overflow: hidden;
  border: 2px dotted #FFF;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #17101f;
}

.image-uploader-container input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  cursor: pointer;
}

.image-uploader-container .drop-image {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -44px;
  margin-left: -44px;
}

/******* SNOW *********/
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:absolute;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}

/*.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.css-1t58dob-MuiSvgIcon-root-MuiStepIcon-root {*/
/*  width: 50px;*/
/*  height: 50px;*/
/*}*/

/*.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.css-zpcwqm-MuiStepConnector-root {*/
/*  top: 24px;*/
/*  left: calc(-50% + 25px);*/
/*  right: calc(50% + 25px);*/
/*}*/
.css-1t58dob-MuiSvgIcon-root-MuiStepIcon-root {
  color: #3b3b3b !important;
}
.css-1t58dob-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #62bfb1 !important;
}
.css-1t58dob-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #62bfb1 !important;
}
.nft-or {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 80px;
}
.mockup-loading{
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.new-nft-empty {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#transform-artwork .content {
  padding-top: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #0d0415;
  height: 100%;
}

#transform-artwork .content .artwork-container{
  width: 500px;
  border: thin solid #000;
  position: relative;
  background-color: #fff;
}

.sample-option {
  width: 40px;
  border-radius: 50%;
  margin-right: 7px;
  cursor: pointer;
}
